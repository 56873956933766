<template>
  <div class="w">
    <div class=" w container_aboutme">
       <Banner :bannerImg="bannerImg" :showHiden="showHiden"/>
        <div class="marginTitle120">
           <titleBar  :title="title" :titlemiao="titlemiao"/>
        </div>
        <!-- 介绍公司 -->
        <div class="w aboutInfo flexCoumCenter">
           <div class="w1200 aboutInfoBanner">
             <img class="w1200 infoImg" src="../../image/aboutInfo.png" alt="" srcset="">
             <div class="w1200">
                <div class="infoDaishu">
                     <div class="about_title">代叔仓配</div>
                     <div class="about_fu">代叔科技，以科技服务物流</div>
                     <div class="about_content">
                       2006注册成立的凯翔仓储，于2016重组更名为“代叔科技”。十几年来，代叔不断创新，一直专注小微企业仓配一体化产品打造及服务。代叔依托着粤港澳大湾区城市群，在广佛深运营着十几个仓配基地，总面积几十万平方米，自建绿色配送车队，服务客户数千家，代叔自主研发基于仓配一体化的管理系统，坚持“为客户节约成本33%以上”的服务理念，相继推出托盘仓、定制仓、配送、物流、快递代发等产品，满足从销售开始的订单管理、仓储、运输，最后一公里城市配送等服务。
                     </div>
                </div>
             </div>
           </div>
        </div>
        <!-- 发展历史 -->
        <div class="marginTitle119">
          <titleBar :title="titlee" :titlemiao="titlemiaoo"/>
        </div>
        <div class="w history flexCenter">
           <div class="w1200 flexLine">
             <div class="historyItem " v-on:mouseover="clickChanged(index)" v-for="(item,index) in historyArray" :key="index">
               <img class="historyItemImg" :src="item.historyYear" alt="" srcset="">
               <img :class="active==index?'historyItemData':'historyItemMode'" src="../../image/historyMode.png" alt="" srcset="">
               <div :class="active==index?'historyItemBodyData':'historyItemBody'">
                 {{item.historyContent}}
               </div>
             </div>
            
           </div>
        </div>
        <!-- 代叔亮点 -->
         <div class="marginTitle119">
          <titleBar :title="titleee" :titlemiao="titlemiaooo"/>
         </div>
         <div class="highLights flexCoumCenterrr w">
            <div class="flexLine w1200 flexItem">
              <div>
                <div class="highLights_left flexLine">
                <div class="ld_item marginTop109">
                  <img class="ld_itemImg" src="../../image/ld01.png" />
                </div>
                <div class="ld_itemp">
                  <div class="ld_itemBottom">
                    <div class="ld_itemBottom_data marginTop77">
                      仓储+配送
                    </div>
                    <div class="ld_itemTop_data">
                      代叔仓配以近20个云仓为基础，两百辆自营新能源车为配送动力，以广州为中心辐射2小时车程的都市圈，提供点至点直送、同线拼车送、物流点代送等服务。以降低运输成本、提高运输速度、提升客户满意度为目的，优化、完善仓储配送的供应链。依托智能仓储系统，配送效率最优算法同等车型的配送成本直降100元
                    </div>
                  </div>
                </div>
              </div>
               <div class="highLights_left marginop41 flexLine">
                <div class="ld_item ">
                  <img class="ld_itemImg" src="../../image/ld02.png" />
                </div>
                <div class="ld_itemp">
                  <div class="ld_itemBottom">
                    <div class="ld_itemBottom_data">
                      互联网+管理
                    </div>
                    <div class="ld_itemTop_data">
                      代叔拥有自主研发的订单管理系统OMS、仓储管理系统WMS与TMS运输管理系统，用户可以通过微信公众号/PC端操作货物入仓、出仓、上下架、分拣、物流运输、城配线路等，全流程可视化；更可在线查询产品库存明细、历史订单明细、费用结算、数据分析等。自研智能仓储管理系统、订单系统及运输系统，实现高效管理，有效降低客户成本,全程专业化操作，体系化管理仓储成本直降33%
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="itemReft">
                  <img src="../../image/ldRight.png" alt="" srcset="">
              </div>
            </div>
            <div class="flexLine w1200">
              <div class="flex-item-list flexLine">
               <img src="../../image/zhs.png" class="flex-item-left" alt="" srcset="">
               <div class="flex-item-Right">
                  <div class="flex-item-RList">
                    <div class="flex-item-RList-Top">
                      <div class="flex-item-RList-Top-title flexCoumCenterDivv">
                         <img src="../../image/icon03.png" class="flex-item-icon" />
                         <div class="flex-item-name">
                           职业化团队
                         </div>
                      </div>
                      <div class="flex-item-RList-Top-body">
                        代叔仓储拥有专属服务，专人精细化跟踪对接，快速反应处理各种事件。从客服到仓储配送一体化的管理团队，有统一的操作规范，专业的仓库基础设备，作业流程清晰，服务好仓储运输的每个流程节点。尽最大限度更好地满足顾客需求，保证顾客要求的时间内准时送达，代叔仓储最大的愿望是服务能达到顾客所要求的，所信赖的。
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-RList flex-item-RListTop">
                    <div class="flex-item-RList-Top">
                      <div class="flex-item-RList-Top-title flexCoumCenterDivv">
                         <img src="../../image/icon04.png" class="flex-item-icon" />
                         <div class="flex-item-name">
                           红酒仓基地
                         </div>
                      </div>
                      <div class="flex-item-RList-Top-body">
                        代叔仓配深耕“红酒恒温仓”多年，有着丰富的红酒仓储加工运输经验。根据红酒存储特性，在仓库内部增设智能恒温恒湿控制系统、避光、防尘等仪器设备，确保温度始终保持在产品需求温度，并定时有专人到各温湿度监测点做记录与监测。代叔提供一整套的红酒增值服务，包括多产品组合、更换木箱、撕贴标签、条码采集、打托缠膜等。不管用户身在何地，手机/PC端远程下单，即可完成订单的所有操作。
                      </div>
                    </div>
                  </div>
               </div>
              </div>
            </div>
         </div>
         <!-- 经典仓储经验 -->
         <div class="marginTitle119">
          <titleBar :title="titleee" :titlemiao="titlemia4"/>
         </div>
         <!-- 经典仓储经验banner -->
         <div class="banner-banner w">
           <div class="banner-banner-item w flexLinee">
             <img class="banner-banner-Img" src="../../image/jdcc.png" alt="" srcset="">
             <div class="banner-banner-list w1200 flexLineec">
               <div class="banner-list-item" v-on:mouseover="clickBannerChanged(i)" v-for="(item,i) in bannerData" :key="i">
                 <img class="banner-list-img" :src="item.imgSrc">
                 <div class="banner-mode" v-if="bannerActive==i">
                 </div>
                 <div class="banner-radius" v-if="bannerActive==i">
                   
                 </div>
                 <div class="banner-solid" v-if="bannerActive==i">
                   
                 </div>
                 <div class="banner-text" v-if="bannerActive==i">
                   <div>
                     {{item.name}}
                   </div>
                   <div>
                     {{item.name2}}
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/banner/banner.vue";
import titleBar from "@/components/titleBar/titleBar.vue";
import "@/assets/comm/comm.css";
export default {
  data() {
    return {
      bannerImg: require("../../image/aboutMe.png"),
      title: "公司介绍",
      titlemiao: "COMPANY PROFILE",

      titlee: "发展历史",
      titlemiaoo: "DEVELOPMENT HISTORY",

      titleee: "代叔亮点",
      titlemiaooo: "DAI SHU'S HIGHLIGHTS",
      titl4: "经典仓储经验",
      titlemia4: "CLASSIC WAREHOUSING EXPERIERIENCE",
      showHiden: true,
      historyArray: [
        {
          historyYear: require("../../image/history2006.png"),
          historyContent:
            "2015年，是代叔仓储的多元化和突破性发展的好时机年间。代叔仓储在此向全社会推出一站式仓储模式和仓配一体化的理念，全面推动仓储物流行业的发展，仓储总面积达100000平方米的新突破。"
        },
        {
          historyYear: require("../../image/history2011.png"),
          historyContent:
            "2015年，是代叔仓储的多元化和突破性发展的好时机年间。代叔仓储在此向全社会推出一站式仓储模式和仓配一体化的理念，全面推动仓储物流行业的发展，仓储总面积达100000平方米的新突破。"
        },
        {
          historyYear: require("../../image/history2016.png"),
          historyContent:
            "在代叔仓配的可持续发展的经营理念下，代叔仓储新推出精品定制仓，满足不同企业的仓储存放需求。精品定制仓的推出得到广大企业的好评，为此，代叔仓储面积又突破到100万平方米的新辉煌。"
        },
        {
          historyYear: require("../../image/history2019.png"),
          historyContent:
            "2019年是代叔仓储的飞跃式发展的年，石门代叔产业启动，仓储总面积达60万平方米。为此，我们深耕许久，为社会全面发展仓储物流行业献出自己的一份力量。未来，代叔仓储继续书写时代的奇迹。"
        }
      ],
      active: 0,
      // 经典仓储经验
      bannerData:[{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/hj.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba2.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba3.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba4.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba5.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba6.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba7.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba8.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba9.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba10.png')
      },{
        name:'红酒仓配',
        name2:'解决方案',
        imgSrc:require('../../image/ba11.png')
      }],
      bannerActive:0
    };
  },
  methods: {
    clickChanged(index) {
      this.active = index;
    },
    clickBannerChanged(i){
      this.bannerActive=i
    }
  },
  components: {
    Banner,
    titleBar
  }
};
</script>

<style lang="less" scoped>
.flexItem {
  margin-bottom: 51px;
}
.marginTitle120 {
  margin-top: 120px;
  height: 50px;
  line-height: 50px;
}
.history {
  margin-top: 36px;
}
.marginTitle119 {
  margin-top: 119px;
  height: 50px;
  line-height: 50px;
}
.aboutInfo {
  margin-top: 36px;
  height: 500px;
  .aboutInfoBanner {
    position: relative;
    height: 500px;
    .infoImg {
      height: 500px;
      position: absolute;
    }
    .infoDaishu {
      color: #fff;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 59px;
      .about_title {
        width: 541px;
        height: 48px;
        line-height: 48px;
        margin-top: 83px;
        font-size: 50px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fefefe;
      }
      .about_fu {
        width: 541px;
        height: 21px;
        line-height: 21px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fefefe;
        margin-top: 21px;
        margin-bottom: 43px;
      }
      .about_content {
        width: 541px;
        height: 185px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fefefe;
      }
    }
  }
}
.historyItem:last-child {
  margin-right: 0px;
}
.historyItemData {
  visibility: visible;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 77%;
  z-index: 10;
}
.historyItem {
  box-sizing: border-box;
  width: 285px;
  height: 428px;
  position: relative;
  margin-right: 20px;
  .historyItemImg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .historyItemMode {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 77%;
    z-index: 10;
    visibility: hidden;
    transition: opacity 2s;
  }

  .historyItemBody {
    position: absolute;
    z-index: 99;
    margin: 124px 33px 143px 33px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    visibility: hidden;
    transition: opacity 2s;
  }
  .historyItemBodyData {
    position: absolute;
    z-index: 99;
    margin: 124px 33px 143px 33px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
  }
}
.highLights {
  margin-top: 44 px;
}
.marginTop109 {
  margin-top: 109px;
}
.ld_item {
  width: 56px;
  height: 54px;
  .ld_itemImg {
    width: 100%;
    height: 100%;
  }
}
.marginTop77 {
  margin-top: 111px;
}
.marginop41 {
  margin-top: 41px;
}
.ld_itemp {
  .ld_itemBottom {
    margin-left: 19px;

    .ld_itemBottom_data {
      font-size: 34px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #f36a4a;
    }
    .ld_itemTop_data {
      margin-top: 20px;
      width: 452px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
    }
  }
}
.itemReft {
  margin-top: 44px;
  width: 576px;
  height: 579px;
  margin-left: 86px;
  img {
    width: 100%;
    height: 100%;
  }
}
.flex-item-list {
  width: 100%;
  position: relative;
  height: 667px;
  .flex-item-left {
    position: absolute;
    width: 705px;
    height: 667px;
  }
  .flex-item-Right {
    position: absolute;
    margin-left: 666px;
    margin-top: 67px;
    .flex-item-RList {
      .flex-item-RList-Top {
        .flex-item-RList-Top-title {
          .flex-item-icon {
            width: 56px;
            height: 54px;
          }
          .flex-item-name {
            font-size: 34px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #f36a4a;
            margin-left: 27px;
          }
        }
        .flex-item-RList-Top-body {
          width: 452px;

          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
          margin-left: 83px;
          margin-top: 24px;
        }
      }
    }
  }
  .flex-item-RListTop {
    margin-top: 45px;
  }
}
.banner-banner-item {
  position: relative;
  width: 100%;
  height: 716px;
  margin-top: 49px;
}
.banner-banner-Img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.banner-banner-list {
  position: absolute;
  color: #fff;

  margin-top: 72px;    
}
.banner-list-item {
  position: relative;
  width: 270px;
  height: 153px;
  margin: 0px 29px 57px 0;
  .banner-list-img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .banner-mode {
    position: absolute;

    width: 278px;
    height: 153px;
    background: #000000;
    opacity: 0.65;
  }
  .banner-radius {
    position: absolute;
    width: 133px;
    height: 133px;
    border: 2px dashed #ffffff;
    opacity: 0.3;
    border-radius: 50%;
    top: 50%;
    left: 25%;
    // transform: translateX(-50%);
    transform: translateY(-50%);
  }
  .banner-solid {
    position: absolute;
    width: 109px;
    height: 109px;
    background: #ffffff;
    opacity: 0.3;
    border-radius: 50%;
    top: 50%;
    left: 30%;
    transform: translateY(-50%);
  }
  .banner-text {
    position: absolute;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    border-radius: 50%;
    top: 50%;
    left: 38%;
    transform: translateY(-50%);
  }
}
</style>
