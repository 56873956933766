<template>
  <div>
  <div class="contaier_shouye">
    <div class="contaier_myIndex">
      <a-carousel class="contaier_carousel" autoplay>
        <div class="bannerImg">
          <img src="~@/image/shouye.jpg" alt="" srcset="" />
        </div>
        <div class="bannerImg">
          <img src="~@/image/yewfwbanner.png" alt="" srcset="" />
        </div>
        <div class="bannerImg">
          <img src="~@/image/aboutMe.png" alt="" srcset="" />
        </div>
        <div class="bannerImg">
          <img src="~@/image/bannerCon.png" alt="" srcset="" />
        </div>
      </a-carousel>
      <div class="inputBanner">
        <div class="inputItem">
          <div class="input_title">物流查询</div>
          <div class="inputSearch">
            <a-input-search
              placeholder="请输入运单"
              @search="onSearch"
              enter-button="查询"
              size="large"
            />
          </div>
        </div>
      </div>
      <!-- <div class="banner_swper">
        <div class="w1200 swperItem">
          <img class="imgNao" src="~@/image/naozong.png" alt="" srcset="" />
          <div class="noticeContent">
            <ul
              class="notice_list"
              :class="noticeList.length > 1 ? { notice_top: animate } : ''"
            >
              <li v-for="(item, index) in noticeList" :key="index">
                {{ item.content }}
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    </div>
    <div class="matop">
      <titleBar :title="title" :titlemiao="titlemiao" />
    </div>
    <div class="bannerItem1 flexCenter w">
      <div class="w1200 flexCoumCenterDivv">
        <div
          class="bannerData  marginBu57 flexCoumCenterr"
          v-for="(item, index) in bannerData"
          :key="index"
          @click="gotoShare(index)"
        >
          <div class="bannerData_img marginBu18">
            <img class="image" src="~@/image/bgItem.png" alt="" srcset="" />
            <img :src="item.bgImg" alt="" />
          </div>
          <div class="titleBanner">
            <div class="bannerSizeColor margin12">{{ item.name }}</div>
            <div class="bannerSizeColor14">{{ item.nameTitle }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="matopp">
      <titleBar :title="titlee" :titlemiao="titlemiaoo"/>
    </div>
    <div class="w flexCenter paddingTop75">
        <div class="w1200 flexCoumCenter bannerItemyunc">
          <div class="imgItem marginRight marginbottom flexCoumCenterDiv">
              <img src="../../image/kuaisu01.png">
          </div>
          <div class="paddingLeft18 itembg marginbottom imgItem370">
             <div class=" flexLine ">
               <img class="kuai02" src="../../image/kuaisu02.png"/>
               <div class="itemLeft">云仓储存</div>
             </div>
             <div class="titleBottom">
                <span class="span12">仓储配送一体化</span>
                <span class="span12">专业仓储团队</span>
                <span>实时监控</span>
             </div>
          </div>
            <div class="imgItem marginLeft18 marginbottom flexCoumCenterDiv">
              <img src="../../image/kuasu181.png">
          </div>
               <div class="paddingLeft18 itembg itemBg1 imgItem370">
             <div class=" flexLine ">
               <img class="kuai02" src="../../image/kusai01.png"/>
               <div class="itemLeft">快运专线</div>
             </div>
             <div class="titleBottom">
                <span class="span12">铁路货运专列</span>
                <span class="span12">每天发车</span>
                <span>时效保障</span>
             </div>
          </div>
           <div class="imgItem marginRight flexCoumCenterDiv">
              <img src="../../image/kuasu18.png">
          </div>
     
                <div class="paddingLeft18 itembg itembg2 imgItem370">
             <div class=" flexLine ">
               <img class="kuai02" src="../../image/kusai03.png"/>
               <div class="itemLeft">智能配送</div>
             </div>
             <div class="titleBottom">
                <span class="span12">自有车辆</span>
                <span class="span12">保证速度</span>
                <span>高效专业</span>
             </div>
          </div>
        </div>
    </div>
    <div class="w  marginTop127">
      <div class="topbanner">
         <img class="topbanner_img" src="../../image/bg.png"/>
         <div class="topbanner_data flexCenter">
            <div class="topbanner_item flexbetw">
              <div class="banner_item">
                 <div class="banner_item flexCoumCenterDiv">
                    <div class="year">2006</div>
                    <div class="yearName">年</div>
                 </div>
                 <div class="chengliTime colorClass">代叔仓配成立时间</div>
              </div>
              <div class="banner_item">
                 <div class="banner_item flexLineend">
                    <div class="year">306</div>
                    <div class="yearName">+</div>
                 </div>
                 <div class="chengliTime colorClass">全国覆盖城市</div>
              </div>
              <div class="banner_item">
                 <div class="banner_item flexLineend">
                    <div class="year">800</div>
                    <div class="yearName">+</div>
                 </div>
                 <div class="chengliTime colorClass">商业合作伙伴</div>
              </div>
              <div>
                 <div class="banner_item flexLineend">
                    <div class="year">33000</div>
                    <div class="yearName">+</div>
                 </div>
                 <div class="chengliTime colorClass">好评例子</div>
              </div>
            </div>
            
         </div>
      </div>
    </div>
    <div class="matoppp">
      <titleBar :title="titleee" :titlemiao="titlemiaooo" />
    </div>
    <div class="w flexCenter newBanner">
        <div class="w1200 flexCoumCenterEnd">
            <div class="newItem marginR44 margin43">
              <img class="newItem_img" src="../../image/new01.png"/>
              <div class="newItemImg">
                  <div class="newItem_title">
              </div>
              <div class="newItem_item_Banner">
                 <div class="newItem_ti_Banner">
                    <div class="titleNew newName">雨水又浸街了，</div>
                    <div class="titleNew">你家的仓库该如何防水？</div>
                 </div>
                 <div class="newItem_ti_by">
                    <div>
                        1、随时留意气象部门的洪水、暴雨、台风等气象预报，及时作好防范自然灾害的人员和物资的准备。
                    </div>
                     <div>
                        2、最好库房有人值守，及时发现及时解决，为防止库房进水，要尽可能做好预防措施。
                    </div>
                 </div>
                 <div class="dashedLine"></div>
                 <div class="date">2020/03/09</div>
              </div>
              </div>
            </div>
            <div class="newItem599 margin43">
              <img class="newItem_img280" src="../../image/new02.png"/>
              <div class="newItemImg">
                  <div class="newItem_title">
              </div>
              <div class="newItem_item_Banner">
                 <div class="newItem_ti_Banner">
                    <div class="titleNew newName">仓配一体化，</div>
                    <div class="titleNew">多方面升级模式！</div>
                 </div>
                 <div class="newItem_ti_by">
                    <div>
                        1、自主研发仓配一体化管理系统，定制专业仓储运营方案，退货验收、重新上架、更新库存全程可控。
                    </div>
                     <div>
                        2、强大的信息系统开发运维能力解决，多平台类型商家共享信息平台，自营车队配送网络覆盖城市率广。
                    </div>
                 </div>
                 <div class="dashedLine"></div>
                 <div class="date">2020/03/09</div>
              </div>
              </div>
            </div>
             <div class="newItem599 marginR44">
              <div class="newItemImg">
                  <div class="newItem_title">
              </div>
              <div class="newItem_item_Banner">
                 <div class="newItem_ti_Banner">
                    <div class="titleNew newName">定制自管仓，</div>
                    <div class="titleNew">自主性能高，易管理！</div>
                 </div>
                 <div class="newItem_ti_by">
                    <div>
                        1、根据用户的仓储需求定制包括仓库面积、温度、设备、管理方式等自由组合的定制仓库。
                    </div>
                     <div>
                        2、拥有独门独锁，低至35㎡起租，但需自购仓库设备，由己方仓管负责管理。
                    </div>
                 </div>
                 <div class="dashedLine"></div>
                 <div class="date">2020/03/09</div>
              </div>
              </div>
              <img class="newItem_img280" src="../../image/new03.png"/>
              
            </div>
            <div class="newItem">
              <div class="newItemImg">
                  <div class="newItem_title">
              </div>
              <div class="newItem_item_Banner">
                 <div class="newItem_ti_Banner">
                    <div class="titleNew newName">为什么看起来？</div>
                    <div class="titleNew">代叔仓配的仓库比其他的贵？</div>
                 </div>
                 <div class="newItem_ti_by">
                    <div>
                        1、代叔仓配用的都是较好的装修材料和比较先进的机械设备，提高工作效率。
                    </div>
                     <div>
                        2、仓库安全有保障，仓储园区24小时安保轮值，仓库所有车辆出入均需登记。而且，代叔仓配有专业的客服团队为你解决关于仓库的所有疑问。
                    </div>
                 </div>
                 <div class="dashedLine"></div>
                 <div class="date">2020/03/09</div>
              </div>
              </div>
              <img class="newItem_img" src="../../image/new04.png"/>
            </div>
        </div>
    </div>
    <div class="mrginTop190">
      <footerBanner :footerBanner="footerBanner"/>
    </div>
  </div>
  </div>
</template>
<script>
import titleBar from "@/components/titleBar/titleBar.vue";
import footerBanner from "@/components/footerBanner/footerBanner.vue";
import "@/assets/comm/comm.css";
export default {
  name: "MyIndex",
  data() {
    return {
      footerBanner: require("../../image/footerImg.png"),
      noticeList: [
        {
          content: "苹果也疯狂！iPhone XR来到小米价，刷新售价底线"
        },
        {
          content: "“5号电池一出”，充电宝没用了，所有手机通用，行千里不关机"
        },
        {
          content: "小米10一出手就高配置，小米9难及十分之一，友商都汗颜"
        },
        {
          content: "iPhone XR2性能曝光，A13处理器+后置双摄"
        }
      ],
      bannerData: [
        {
          bgImg: require("../../image/daishu-cang.png"),
          name: "代叔共享仓",
          nameTitle: "省33%以上仓配成本"
        },
        {
          bgImg: require("../../image/daishu-cang.png"),
          name: "代叔袋金票",
          nameTitle: "一票通用  成本更低"
        },
        {
          bgImg: require("../../image/03jimao.png"),
          name: "代叔集贸仓",
          nameTitle: "开发中"
        },
        {
          bgImg: require("../../image/04shangc.png"),
          name: "代叔仓储商城",
          nameTitle: "开发中"
        },
        {
          bgImg: require("../../image/05jipei.png"),
          name: "代叔集配",
          nameTitle: "开发中"
        },
        {
          bgImg: require("../../image/06hehuoren.png"),

          name: "代叔合伙",
          nameTitle: "开发中"
        },
        {
          bgImg: require("../../image/07Ccang.png"),

          name: "代叔c仓",
          nameTitle: "开发中"
        },
        {
          bgImg: require("../../image/08shengtai.png"),

          name: "生态伙伴",
          nameTitle: "开发中"
        }
      ],
      animate: false,
      title: "产品与服务",
      titlemiao: "PRODUCTS AND SERVICES",

      titlee: "快速与可靠",
      titlemiaoo: "FAST AND SERVICES",

      titleee: "新闻与资讯",
      titlemiaooo: "NEWS AND INFORMATION"
    };
  },
  components: {
    titleBar,
    footerBanner
  },
  created() {
    // 页面显示
    let t = this;
    setInterval(t.showNotice, 3000);
  },
  methods: {
    onSearch(value) {
      console.log(value);
    },
    showNotice() {
      let t = this;
      t.animate = true;
      setTimeout(() => {
        t.noticeList.push(t.noticeList[0]);
        t.noticeList.shift();
        t.animate = false;
      }, 500);
    },
    gotoShare(index) {
      window.sessionStorage.setItem("index", JSON.stringify(index+1));
      if (index == 0) {
        this.$router.push({
          path: "/sharedWarehouse",
          query: {
            id:index+1
          }
        });
      }
      if(index == 1){
         
         this.$router.push({
          path: "/sharedWarehouse",
          query: {
            id:index+1
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.contaier_shouye {
  color: #000;
}
.mrginTop190 {
  margin-top: 190px;
}
.contaier_myIndex {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  clear: both;
}
.contaier_carousel {
  width: 1900px;
}
.inputBanner {
  position: absolute;
  width: 963px;
  height: 193px;
  background: #ffffff;
  opacity: 0.8;
  z-index: 32;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  .inputItem {
    margin: 44px 0px 0px 37px;
    position: relative;
    height: 104px;
    opacity: 1;
    width: 887px;
    .input_title {
      text-align: start;
      width: 123px;
      opacity: 1;
      height: 30px;
      line-height: 30px;
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .inputSearch {
      width: 887px;
      height: 56px;
      margin-top: 18px;

      border-radius: 28px;
    }
    /deep/.ant-input-group {
      position: relative;
      opacity: 1;
    }
    /deep/.ant-input-group .ant-input-group-addon {
      position: absolute;
      right: 0px;
      width: 128px;
      height: 56px;
      opacity: 0.5;

      background: #ff5400;
      border-radius: 28px;
      opacity: 1 !important;

      z-index: 9999;
      .ant-btn-primary {
        background-color: #ff5400;
        border-color: #ff5400;
        line-height: 56px;
        opacity: 1;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
    }
    /deep/.ant-input-group .ant-input {
      border-radius: 28px;
      background-color: #000000;
      color: #fff;
      height: 56px;
      opacity: 0.5;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #eeeeee;
      padding-left: 38px;
    }
    /deep/.ant-input:focus {
      border-color: #000000;
    }
  }
}
 .slick-dots-bottom {
  bottom: 87px !important;
}
.ant-carousel .slick-slide {
  // width: 1920px;
  height: 544px;
  line-height: 919px;
  background: #364d79;
  overflow: hidden;
}
.bannerImg .img {
  // width: 1920px;
  width: 100%;
  height: 544px;
}
.banner_swper {
  position: absolute;
  bottom: 0px;
  height: 40px;
  // width: 1920px;
  background: #000000;
  opacity: 0.2;
  .swperItem {
    display: flex;
    align-items: center;
    margin-left: 517px;
    box-sizing: border-box;
    color: #fff;
    height: 40px;
    line-height: 40px;
    .imgNao {
      width: 25px;
      height: 27px;
    }
  }
}
.noticeContent {
  margin-top: -13px;
  display: block;
  position: relative;
  width: 100%;
  height: 27px; // 控制高度以达到控制显示条数的目的
  overflow: hidden;
  padding-left: 19px;
  opacity: 1;
}
.noticeContent li {
  text-align: left;
  list-style: none;
  height: 40px;
  opacity: 1;
  font-family: Source Han Sans CN;
  color: #ffffff;
  z-index: 999;
}
.notice_list {
  height: 40px; // 控制高度以达到控制显示条数的目的
  overflow: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 19px;
  padding-bottom: 0px;
}
// .notice_top {
//   transition: all 0.5s;
// }
.matop {
  margin-top: 138px;
  height: 38px;
}
.matopp {
  margin-top: 25px;
  height: 38px;
}
.bannerItem1 {
  margin-top: 65px;
}
.bannerData {
  width: 315px;
}
.bannerData:nth-child(4n) {
  width: 182px;
}
.bannerData_img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerData_img:hover {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerData_img,
.bannerData_img .image {
  width: 182px;
  height: 211px;
}
.bannerData_img img {
  position: absolute;
}
.titleBanner > div {
  text-align: center;
  width: 182px;
}
.bannerItemyunc {
  justify-content: space-between;
}
.imgItem {
  width: 370px;
  height: 290px;
  box-sizing: border-box;
}
.imgItem370 {
  width: 370px;
  height: 290px;
  box-sizing: border-box;
}
.itembg2 {
  background-color: #dcedfc;
}
.kuai02 {
  width: 85px;
  height: 62px;
  margin-right: 21px;
}
.itemLeft {
  width: 204px;
  height: 49px;
  line-height: 49px;
  font-size: 50px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ff4d00;
  text-shadow: -4px 3px 4px #cccccc;
}
.paddingLeft18 {
  padding-left: 18px;
  padding-top: 65px;
}
.titleBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 22px;

  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  opacity: 0.7;
}
.span12 {
  text-align: center;
  margin-bottom: 12px;
}
.marginTop127 {
  margin-top: 127px;
  height: 300px;
}
.topbanner,
.topbanner img {
  height: 300px;
}
.topbanner {
  position: relative;
  right: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  .topbanner_img {
    position: absolute;
    // width: 1920px;
    width: 100%;
  }
  .topbanner_data {
    position: absolute;
    height: 300px;
    .topbanner_item {
      width: 1200px;
      .banner_item {
        .year {
          height: 49px;
          line-height: 49px;
          font-size: 62px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fc6f32;
        }
        .yearName {
          width: 29px;
          height: 30px;
          line-height: 30px;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fc6f32;
          margin-left: 35px;
        }
      }
      .chengliTime {
        margin-top: 42px;
        width: 183px;
        height: 22px;
        line-height: 22px;
        font-size: 22px;
        text-align: center;
      }
    }
  }
}
.matoppp {
  margin-top: 125px;
  height: 38px;
}
.newBanner {
  margin-top: 74px;
  .newItem599 {
    display: flex;
    width: 599px;
    height: 300px;
  }
  .newItem {
    display: flex;

    width: 559px;
    height: 300px;
  }
  .newItem_img {
    width: 250px;
    height: 300px;
  }
  .newItem_img280 {
    width: 280px;
    height: 300px;
  }
  .newItemImg {
    position: relative;
    width: 319px;
  }
  .newItem_title {
    position: absolute;
    height: 300px;
    width: 319px;
    background-color: #e88338;
    opacity: 0.1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .newItem_item_Banner {
    position: absolute;
    padding: 17px 0 16px 31px;
    .titleNew {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ff5c00;
    }
    .newName {
      margin-bottom: 11px;
    }
    .dashedLine {
      position: absolute;
      height: 30px;
      width: 251px;
      border-bottom: 3px dashed #ff4d00;
      opacity: 0.5;
    }
    .date {
      position: absolute;

      right: 0px;
      height: 13px;
      line-height: 13px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: end;
      margin-top: 40px;
    }
  }
  .newItem_ti_by {
    margin-top: 21px;
    div {
      width: 250px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
    }
  }
}
</style>