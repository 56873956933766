<template>
   <div class="w bannerItem">
      <img class="bannerImg" :src="bannerImg1" alt="" srcset="">
      <div class="showAbout w1200" v-if="showHidenn">
           <div class="showItemIcon"  v-for="(item,index) in nameArray" :key="index">
             <img class="showItemImg" src="../../image/bannerItemIcon.png" alt="" srcset="">
             <div class="nameColor">{{item.name}}</div>
           </div>
      </div>
   </div>
</template>
<script>
import "@/assets/comm/comm.css";
export default {
  name: "bannerName",
  props: {
    bannerImg: {
      type: String,
      required: true
    },
    showHiden: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      bannerImg1: "",
      showHidenn:false,
      nameArray: [{ name: "创新" }, { name: "专业" }, { name: "智能" }, { name: "其他" }, { name: "共享" }]
    };
  },
  methods: {},
  created() {
    this.bannerImg1 = this.bannerImg;
    this.showHidenn=this.showHiden
    console.log('this.showHidenn',this.showHiden);
    console.log(this.bannerImg1, "this.bannerImg1");
  }
};
</script>

<style lang="less" scoped>
.bannerItem {
  position: relative;
  height: 544px;
  .showAbout {
    position: absolute;
       display: flex;
    height: 544px;
    .showItemIcon {
      position: relative;
      top: 324px;
      left: 394px;
      margin-right: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: unset;
      width: 83px;
      height: 82px;
      .showItemImg {
        position: absolute;
        width: 83px;
        height: 82px;
      }
      .nameColor {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
.bannerImg {
  height: 544px;
  width: 100%;
  position: absolute;
}
</style>

