<template>
  <div class="contaner-contact">
    <Banner :bannerImg="bannerImg"/>
    <div class="magin118">
      <titleBar :title="title" :titlemiao="titlemiao" />
    </div>
    <div class="w flexLinee itemLine">
      <div class="w1200 flexLinee">
        <div class="banner_list">
           <div class="banner_item">
             运营项目
           </div>
           <div class="banner_list_name">
             <div :class="active==i? 'banner_name_active':'banner_name'" @mouseover="mousChanged(i)" v-for="(item,i) in bannerLeft" :key="i">
                {{item}}
             </div>
           </div>
           <div class="banner_list_footer">
             <img class="banner_list_img" src="../../image/title.png" alt="" srcset="">
             <div class="banner_list_item">
               <div class="banner_list_text">服务热线</div>
               <div class="banner_list_ph">400-8520-157</div>
             </div>
             <div class="banner_list_item2">
               <div class="banner_list_hh">
                 电话：400-8520-157
               </div>
               <div>
                 邮箱：2355548182@qq.com
               </div>
             </div>
           </div>
        </div>
        <div class="banner_right">
          <div class="banner_right_map">
            <img src="../../image/map.png" alt="" srcset="">
          </div>
          <div class="banner_body">
            <div class="contactAdreess">
              联系地址
            </div>
            <div class="margin16">
              名称：广州代叔科技有限公司
            </div>
            <div class="margin16">
              地址：广州市白云区石井镇朝阳田心23号
            </div>
            <div class="margin16">
              官网：www.kxcc.cn
            </div>
          </div>
          <!-- nav -->
          <div>
            <!-- <Carousel/> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/banner/banner.vue";
import titleBar from "@/components/titleBar/titleBar.vue";
// import Carousel from "@/components/carousel/index.vue";

export default {
  data() {
    return {
      bannerImg: require("../../image/bannerCon.png"),
      title: "联系我们",
      titlemiao: "CONTACT US",
      bannerLeft: ["公共仓托管", "定制仓", "货物库内加工", "自研仓库管理"],
      active: 0
    };
  },
  methods: {
    mousChanged(i) {
      this.active = i;
    }
  },
  components: {
    Banner,
    titleBar,
    // Carousel
  }
};
</script>
<style lang="less" scoped>
.contaner-contact {
  margin-bottom: 110px;
  .magin118 {
    margin-top: 118px;
    height: 40px;
    line-height: 40px;
  }
  .itemLine {
    margin-top: 38px;
  }
  .banner_list {
    width: 300px;
    .banner_item {
      width: 300px;
      height: 111px;
      line-height: 111px;
      background: #ff4d00;
      text-align: center;
      font-size: 44px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }
    .banner_list_name {
      margin-top: 41px;
      .banner_name {
        width: 100%;
        height: 78px;
        line-height: 78px;
        text-align: center;
        background-color: #cccccc;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 12px;
      }
      .banner_name_active {
        width: 100%;
        height: 78px;
        line-height: 78px;
        text-align: center;
        background-color: #ff4d00;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 12px;
      }
    }
    .banner_list_footer {
      box-sizing: content-box;
      border: 1px solid #ff4d00;
      margin-top: 19px;
      width: 100%;
      .banner_list_img {
        width: 100%;
        height: 212px;
      }
      .banner_list_item {
        padding: 25px 0 23px 20px;
        border-bottom: 2px dashed #000;
      }
      .banner_list_text {
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #cccccc;
      }
      .banner_list_ph {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff4d00;
      }
      .banner_list_item2 {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #666666;
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        .banner_list_hh {
          margin-bottom: 18px;
        }
      }
    }
  }
  .banner_right {
    margin-left: 39px;
    width: 858px;
    box-sizing: border-box;
    .banner_right_map {
      width: 100%;
      height: 448px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .banner_body {
      margin-top: 40px;
      padding: 35px 0 0 23px;
      width: 100%;
      height: 207px;
      padding-top: 35px;
      padding-left: 23px;
      background-color: #fb9569;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      .contactAdreess {
        font-size: 30px;
        margin-bottom: 22px;

      }
      .margin16 {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
