<template>
  <div class="titleBar">
    <div class="titleContent">
      <div class="titleItem">{{titlemiao1}}</div>
      <div class="titleServe">{{title1}}</div>
      <div class="bottomLine"></div>
      <div class="bottomLine5"></div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    title:{
      type:String,
      required:true,
    },
    titlemiao:{
      type:String,
      required:true,
    }
  },
  data() {
    return {
      title1:'',
      titlemiao1:''
    };
  },
  created:function(){
    this.title1=this.title
    this.titlemiao1=this.titlemiao
  }
};
</script>
<style lang="less" scoped>
.titleBar {
  position: relative;
  display: flex;
  justify-content: center;
  // align-items: center;
  .titleContent {
    width: 1200px;
    position: absolute;
    .titleItem {
      position:relative;
      // width: 662px;
      height: 40px;
      line-height: 40px;
      font-size: 50px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-style: italic;
      color: #ff5c00;
      opacity: 0.3;
      box-sizing: border-box;
      bottom: 6px;
    }
    .bottomLine {
      width: 1200px;
      height: 2px;
      background: #ff5c00;
      opacity: 0.5;
      position: absolute;
      bottom: 0;
    }
    .titleServe {
      width: 207px;
      height: 38px;
      line-height: 38px;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ff4d00;
      position: absolute;
      bottom: 22px;
    }
    .bottomLine5 {
      width: 217px;
      height: 5px;
      background: #ff5c00;
      position: absolute;
      bottom: 0;
    }
  }
}
</style>