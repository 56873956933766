<template>
  <div class="w">
    <Banner :bannerImg="bannerImg"/>
    <div class="marginTitle">
       <titleBar  :title="title" :titlemiao="titlemiao"/>
    </div>
    <div class="fuwItem flexCenter">
       <div class="w1200 flexItem flexLine">
          <div class="fuwItem_item" v-for="(item,index) in bannerArrey" :key="index">
            <div class="fuwItem_data">
            </div>
            <div class="fuwItem_dataItem">
             <div class="fuwItem_Img">
               <img class="fuwItem_ImgTuoguan" :src="item.imgIcon" alt="" srcset="">
             </div>
             <div class="fuwItem_title">
               {{item.name}}
             </div>
             <div class="fuwItem_content">
                {{item.content}}
             </div>
             <div class="margin50">
               <div class="xiangq">
                 详情
               </div>
             </div>
           </div>
          </div>
       </div>
    </div>
     <div class="marginTitlee">
       <titleBar  :title="titlee" :titlemiao="titlemiaoo"/>
    </div>
    <!-- 仓储优势 -->
    <div class="w flexCenter margintop40">
      <div class="w1200 cang_Item flexLine">
         <img class="cang_youshi" src="../../image/youshi.png" alt="" srcset="">
         <div class="cang_youshiImg flexCoumCenterEnd">
            <div class="cz_item" v-for="(item,index) in cangcuArray" :key="index">
               <img class="imgbanner" :src="item.bannerImg" alt="" srcset="">
               <div class="modeImg">
                  <img class="modelih" :src="item.mode" alt="" srcset="">
                  <div class="modeImgBody">
                     <div class="lhImg">
                       <img :src="item.linghuo" alt="" srcset="">
                       <div class="divTitle">
                         {{item.titleName}}
                       </div>
                       <div class="rmydiv">
                         {{item.rmydiv}}
                       </div>
                     </div>
                  </div>
               </div>
            </div>
           
         </div>
         
      </div>
    </div>
    <div class="marginTitle124">
       <titleBar  :title="titleee" :titlemiao="titlemiaooo"/>
    </div>
    <div class="marginTop39">
      <footerBanner :footerBanner="footerBanner"/>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/banner/banner.vue";
import titleBar from "@/components/titleBar/titleBar.vue";
import footerBanner from "@/components/footerBanner/footerBanner.vue";

import "@/assets/comm/comm.css";
export default {
  name: "Business",
  data() {
    return {
      bannerImg: require("../../image/yewfwbanner.png"),
      title: "仓储服务",
      titlemiao:'WAREHOUSING SERVICES',
      titlee: "仓储优势",
      titlemiaoo:'WAREHOUSING ADVANTAGES',

      titleee:'商家合作',
      titlemiaooo:'BUSINESS COOPERATION',

      bannerArrey: [
        {
          imgIcon: require("../../image/tuoguan.png"),
          name: "公共仓托管",
          content:
            "首创以托盘为单位的仓储模式，适用“货物少”，“周转快”，“存储时间长”的情况。"
        },
        {
          imgIcon: require("../../image/dingzhi.png"),
          name: "定制仓",
          content:
            "根据客户的需求，提供个性化服务，仓库面积，设备设施，温湿度，管理方式等等。"
        },
        {
          imgIcon: require("../../image/jiagong.png"),
          name: "货物库内加工",
          content:
            "提供货物流通加工增值服务 ，包括装卸货，上下架，二次包装，撕贴标签，代发物流等等。"
        },
        {
          imgIcon: require("../../image/ppzy.png"),
          name: "自研仓库管理",
          content:
            "代叔自主研发oms订单管理系统，wms仓库管理系统，tms运输系统，随时控制仓储情况，全程可视化。"
        }
      ],
      //仓储优势
      cangcuArray: [
        {
          bannerImg: require("../../image/czlh1.png"),
          mode: require("../../image/mode.png"),
          linghuo: require("../../image/linghuo.png"),
          titleName: "灵活出租",
          rmydiv: "日期、月租、年租、按托盘组"
        },
        {
          bannerImg: require("../../image/new01.png"),
          mode: require("../../image/mode.png"),
          linghuo: require("../../image/dinzhiIocn.png"),
          titleName: "仓库定制化",
          rmydiv: "按需定制，定制专属企业仓库"
        },
        {
          bannerImg: require("../../image/anquan03.png"),
          mode: require("../../image/mode.png"),
          linghuo: require("../../image/bianjieIcon.png"),
          titleName: "安全保障",
          rmydiv: "仓库靠近物流园、高速公路、国道"
        },
        {
          bannerImg: require("../../image/anquanbz04.png"),
          mode: require("../../image/mode.png"),
          linghuo: require("../../image/anquanIcon.png"),
          titleName: "安全保障",
          rmydiv: "独立仓库、配备视频监控，24小时管理"
        },
        {
          bannerImg: require("../../image/zyxt05.png"),
          mode: require("../../image/mode.png"),
          linghuo: require("../../image/ziyngIcon.png"),
          titleName: "安全保障",
          rmydiv: "独立仓库、配备视频监控，24小时管理"
        },
        {
          bannerImg: require("../../image/fuwu06.png"),
          mode: require("../../image/mode.png"),
          linghuo: require("../../image/fuIcon.png"),
          titleName: "服务一对一",
          rmydiv: "全程实施一对一的跟踪服务"
        }
      ],
      footerBanner:require("../../image/hezuoshangjia.png"),
    };
  },
  components: {
    Banner,
    titleBar,
    footerBanner
  }
};
</script>
<style lang="less" scoped>
.margintop40 {
  margin-top: 40px; 
}
.marginTop39 {
  margin-top: 39px;
}
.marginTitle {
  margin-top: 119px;
  height: 50px;
  line-height: 50px;
}
.marginTitle124 {
  margin-top: 119px;
  height: 50px;
  line-height: 50px;
}
.marginTitlee {
  margin-top: 120px;
  height: 50px;
  line-height: 50px;
}
.fuwItem {
  margin-top: 37px;

  .flexItem {
    .fuwItem_item {
      width: 285px;
      margin-right: 20px;
    }
    .fuwItem_item:last-child {
      margin-right: 0px;
    }
    position: relative;
    height: 350px;
    .fuwItem_data {
      position: absolute;
      width: 285px;
      height: 350px;
      background: #ff5c00;
      opacity: 0.1;
      box-sizing: border-box;
      padding: 20px 26px 29px 26px;
    }
    .fuwItem_dataItem {
      position: absolute;
      width: 285px;
      height: 350px;
      box-sizing: border-box;
      padding: 20px 26px 29px 26px;
      .fuwItem_Img {
        display: flex;
        justify-content: center;
        .fuwItem_ImgTuoguan {
          width: 79px;
          height: 78px;
        }
      }
      .fuwItem_title {
        text-align: center;
        margin-top: 31px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff4d00;
        opacity: 0.9;
      }
      .fuwItem_content {
        margin-top: 28px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #666666;
        line-height: 24px;
      }
      .margin50 {
        margin-top: 24px;
        height: 43px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #f36a4a;
        .xiangq {
          text-align: center;
        }
        .xiangq:hover {
          line-height: 43px;
          height: 43px;
          background: #f36a4a;
          text-align: center;
          width: 117px;
          font-size: 22px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fbf4f1;
        }
      }
      .margin50:hover {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        height: 43px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #f36a4a;
      }
    }
  }
}
.cang_Item {
  height: 600px;
  box-sizing: border-box;
  .cang_youshi {
    width: 285px;
    height: 600px;
  }
  .cang_youshiImg {
    width: 915px;
    height: 100%;
    .cz_item {
      position: relative;
      width: 285px;
      height: 290px;
      margin-left: 20px;
      margin-bottom: 20px;
      img,
      .modeImg {
        z-index: 1;
        position: absolute;
        width: 285px;
        height: 290px;
        transition: opacity 1s ease-in-out;
      }
      .modeImg .modelih {
        z-index: 9;
      }
      .modeImg {
        z-index: 99;
        opacity: 0;
        color: #fff;
        .modeImgBody {
          position: absolute;
          z-index: 999;
          .lhImg {
            width: 285px;
            height: 290px;
            padding-top: 56px;
            display: flex;
            align-items: center;
            flex-direction: column;
            img {
              width: 67px;
              height: 65px;
            }
            .divTitle {
              width: 197px;
              height: 37px;
              text-align: center;
              line-height: 37px;
              margin-top: 108px;
              font-size: 38px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #fff;
            }
            .rmydiv {
              margin-top: 17px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #fff;
            }
          }
        }
      }
    }
    //  .cz_item .modeImg {
    //     opacity: 0;
    // }
    .cz_item:hover .modeImg {
      opacity: 1;
    }
  }
}
</style>
