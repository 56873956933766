<template>
   <div>
    <div class="w footerImg">
       <img class="fImg" :src="footerBannerImg" alt="" srcset="">
    </div>
   </div>
</template>
<script>
export default {
  name: "footerBanner",
  data() {
    return {
      footerBannerImg:'',
    };
  },
   props:{
     footerBanner:{
       type:String,
        required:true,
     }
   },
   created() {
     this.footerBannerImg=this.footerBanner
   },
};
</script>

<style lang="less" scoped>
.footerImg {
  background-color: #fff;
  height: 500px;
  box-sizing: border-box;
  .fImg {
    height: 100%;
    width: 100%;
  }
}
</style>
