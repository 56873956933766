import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/header',
    name: 'Header',
    component: () => import('../components/header/header.vue')
  },
  {
    path:'/business',
    name:'业务范围',
    component: () => import('../components/scopeBusiness/business.vue')
  }
  ,
  {
    path:'/aboutUs',
    name:'aboutUs',
    component: () => import('../components/aboutUs/me.vue')
  },
  {
    path:'/contactUs',
    name:'contactUs',
    component: () => import('../components/contactUs/contactUs.vue')
  },
  {
    path:'/titleBar',
    name:'titleBar',
    component: () => import('../components/titleBar/titleBar.vue')
  },
  {
    path:'/footer',
    name:'footer',
    component: () => import('../components/footer/footer.vue')
  },
  {
    path:'/banner',
    name:'bannerName',
    component: () => import('../components/banner/banner.vue')
  },
  {
    path:'/footerBanner',
    name:'footerBanner',
    component: () => import('../components/footerBanner/footerBanner.vue')
  },
  {
    path:'/login',
    name:'登录',
    component: () => import('../components/login/login.vue')
  },
  // 子路由首页
  {
    path:'/sharedWarehouse',
    name:'sharedWarehouse',
    component: () => import('../views/sharedWarehouse/sharedWarehouse.vue'),
    children:[
     {
        path:'inWarehousing',
        name:'inWarehousing',
        component:() => import('../components/inWarehousing/inWarehousing.vue')
      },{
        path:'outWarehousing',
        name:'outWarehousing',
        component:() => import('../components/outWarehousing/outWarehousing.vue')
      },{
        path:'inputSelect',
        name:'inputSelect',
        component:() => import('../components/inputSelect/inputSelect.vue')
      },{
        path:'goodsItem',
        name:'下订单',
        component:() => import('../components/goodsItem/goodsItem.vue')
      },{
        path:'paySucess',
        name:'下单成功',
        component:() => import('../components/paySucess/paySucess.vue')
      },{
        path:'warehouseTicket',
        name:'仓票',
        component:() => import('../components/warehouseTicket/warehouseTicket.vue')
      },{
        path:'shopSuccess',
        name:'金袋票',
        component:() => import('../components/shopSuccess/shopSuccess.vue')
      },{
        path:'personalCenter',
        name:'个人中心',
        component:() => import('../components/personalCenter/personalCenter.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
