<template>
  <div class="id">
    <div class="flexbetw flexbetwbgHeader">
      <div class="w1200 flexbetwbg">
        <div class="flexbetwbg_left">
          代叔官网
        </div>
        <div class="flexbetwbg_right">
          <span @click="goLogin">您好，欢迎登录</span>
          <span @click="goregister">请注册</span>
          <span>联系客服</span>
        </div>
      </div>
    </div>
    <a-layout id="components-layout-demo-basic w">
      <a-layout-header class="w1200 flexbetw  header">
        <div class="img">
          <img
            src="~@/image/logo.png"
            alt="logo"
          />
        </div>
        <div class="tabHeight">
          <a-tabs
            :default-active-key="keyTab"
            @change="callback"
          >
            <a-tab-pane
              :tab="item.tabName"
              v-for="item in tabData"
              :key="item.id"
            ></a-tab-pane>
              </a-tabs>
        </div>
      </a-layout-header>
    </a-layout>
  </div>
</template>

<script>
import "@/assets/comm/comm.css";
export default {
  name: "Header",
  data() {
    return {
      tabData: [
        {
          id: 1,
          tabName: "网站首页"
        },
        {
          id: 2,
          tabName: "业务范围"
        },
        {
          id: 3,
          tabName: "关于我们"
        },
        {
          id: 4,
          tabName: "联系我们"
        }
      ],
      keyValue: 1
    };
  },
  created: function() {
    console.log(this.keyTab, "keyValue123");
  },
  props: {
    keyTab: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    callback(key) {
      this.$emit("changTab", key);
    },
    goregister() {
      this.$router.push({
        path: "/login",
        query: {
          id: 1
        }
      });
    },
    goLogin() {
      this.$router.push({
        path: "/login",
        query: {
          id: 2
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.id {
  background-color: #fff;
}
.ant-layout {
  display: flex;
  flex-direction: unset;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 5px;
}
#components-layout-demo-basic {
  .ant-layout-header {
    color: #000;
    height: 100px;
    padding: 0;
    background-color: #fff;

    line-height: 100px;
    /deep/.ant-tabs-bar {
      border-bottom: unset;
    }
  }
  .header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    .img img {
      height: 52px;
    }
    .tabHeight {
      height: 100px;
      line-height: 100px;
      box-sizing: border-box;

      padding-top: 25px;
      /deep/.ant-tabs-tab {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #525252;
        padding-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 42px;
      }
      /deep/ .ant-tabs-nav .ant-tabs-tab-active {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff4d00;
      }
      /deep/.ant-tabs-nav .ant-tabs-tab:hover {
        color: #ff4d00;
      }
      /deep/.ant-tabs-ink-bar {
        background-color: #ff4d00;
        width: 59px !important;
      }
    }
  }
}
.flexbetwbgHeader {
  display: flex;
  justify-content: center;
  background-color: #eeeeee;
  .flexbetwbg {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #666666;
    height: 42px;
    line-height: 42px;
    display: flex;
    justify-content: space-between;
    .flexbetwbg_right {
      display: flex;
      span {
        margin-right: 32px;
      }
      span:hover {
        cursor: pointer;
        color: #ff4d00;
      }
    }
  }
}
</style>