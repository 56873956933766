<template>
  <div>
     <!-- footerImg -->

    <!-- footer -->
     <div class="footer flexCenter">
       <div class="w1200 footerInfo">
         <div class="footerTop">
          <ul class="footerUl">
           <li>关于我们</li>
           <li>联系我们</li>
           <li>联系客服</li>
           <li>合作招商</li>
           <li>友情链接</li>
           <li>隐私政策</li>
           <li>代叔云仓</li>
           <li>业务范围</li>
           <li>行业资讯</li>
           <li>帮助中心</li>
         </ul>
         </div>
         <div class="footerBody flexLine">
            <div class="footerBody-Left">
              <div>名称：广州代叔科技有限公司</div>
              <div>地址：广州市白云区石井镇朝阳田心23号</div>
              <div>电话：400-8520-157</div>
              <div>邮箱：2355548182@qq.com</div>
              <div>官网：www.kxcc.cn</div>
            </div>
            <div class="footerBody-Center">
              <div class="divStyle">
                让我们更近一些，您可以
              </div>
              <div class="divStyle">
                马上预留您联系方式到下方留言区，会有专人替您解答！
              </div>
              <div class="inputCant">
                <a-form :form="form" @submit="handleSubmit">
                <a-form-item>
                     <a-input placeholder="您的姓名:"  />
                 </a-form-item>
                 <a-form-item>
                    <a-input placeholder="您的电话号码:" />
                 </a-form-item>
                 <a-form-item>
                    <a-input placeholder="您的留言反馈:" />
                 </a-form-item>
                 <a-form-item >
                    <a-button  html-type="submit">
                       马上提交
                    </a-button>
                  </a-form-item>
                </a-form>
              </div>
            </div>
            <div class="footerBody-rigeth">
              <div class="footerItem"> 
                <div class="footerItemBy">
                  <img src="../../image/wxgzh.png">
                  <div>
                    微信公众号
                  </div>
                </div>
              </div>
              <div class="footerItem"> 
                <div class="footerItemBy">
                  <img src="../../image/gferweima.png">
                  <div>
                    官网二维码  
                  </div>
                </div>
              </div>
            </div>
         </div>
       </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Footer",
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
      name: ""
    };
  },
  
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      // console.log(e,'ee');
      console.log(this.form, "this.form");
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.footer {
  background-color: #CCCCCC;
  opacity: 0.85;
  height: 355px;
  box-sizing: border-box;
  // background-color: #cccccc;
  .footerInfo {
    height: 100%;
    .footerTop {
      width: 100%;
      .footerUl {
        display: flex;
        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-top: 22px;
        li {
          padding-right: 28px;
          padding-left: 28px;
          border-right: 2px solid #999999;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
        }
        li:first-child {
          padding-left: 0px;
        }
        li:last-child {
          border-right: 0;
        }
      }
    }
    .footerBody {
      margin-top: 28px;
      .footerBody-Left {
        margin-right: 63px;
        div {
          margin-bottom: 18px;
          width: 343px;
          height: 18px;
          line-height: 18px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
        }
      }
      .footerBody-Center {
        width: 422px;
        .divStyle {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
        }
        .inputCant {
          margin-top: 17px;
        }
        div:first-child {
          // margin-bottom: 8px;
          font-size: 18px;
        }
        .ant-input {
          background: #eee;
          border: 2px solid #ffffff;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #999999;
        }
        .ant-btn {
          width: 422px;
          height: 35px;
          background: #ffffff;
          border: 2px solid #ffffff;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
        }
        .ant-btn:hover,
        .ant-btn:focus {
          width: 422px;
          height: 35px;
          background: #ffffff;
          border: 2px solid #ffffff;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
        }
      }
    }
    .ant-form-item {
      margin-bottom: 10px;
    }
  }
  .footerBody-rigeth {
    margin-left: 65px;
    display: flex;
    .footerItem {
      display: flex;
      justify-content: center;
      text-align: center;
      .footerItemBy div {

        height: 18px;
        line-height: 18px;
        text-align: center;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #666666;
      }
      .footerItemBy img {
        width: 120px;
        height: 120px;
        margin-bottom: 29px;
      }
    }
    .footerItem:first-child {
      margin-right: 66px;
    }
  }
}
</style>


