<template>
  <div id="components-layout-demo-basic" class="w">
    <a-layout>
      <Header :keyTab="keyTab" v-on:changTab="changTab($event)" />
      <a-layout-content class="bg content">
        <MyIndex v-if="keyTab == 1" />
        <Business v-else-if="keyTab == 2" />
        <AboutUs v-else-if="keyTab == 3" />
        <ContactUs v-else />
      </a-layout-content>
        <Footer />
    </a-layout>
  </div>
</template>

<script>
import Header from "@/components/header/header.vue";
import MyIndex from "@/components/myIndex/myindex.vue";
import Business from "@/components/scopeBusiness/business.vue";
import AboutUs from "@/components/aboutUs/me.vue";
import ContactUs from "@/components/contactUs/contactUs.vue";
import Footer from "@/components/footer/footer.vue";
import "@/assets/comm/comm.css";
export default {
  name: "Home",
  components: {
    Header,
    MyIndex,
    Business,
    AboutUs,
    ContactUs,
    Footer
  },
  data() {
    return {
      keyTab: 1
    };
  },
  methods: {
    changTab(event) {
      this.keyTab = event;
      console.log(this.keyTab,'this.keyTab');
      window.sessionStorage.setItem("keyTab", JSON.stringify(this.keyTab));
    }
  },
  created() {
    // this.keyId = this.$route.query.id;
    try {
      if (JSON.parse(window.sessionStorage.getItem("keyTab") || {})) {
        this.keyTab = JSON.parse(window.sessionStorage.getItem("keyTab") || {});
      }
    } catch (error) {
      
    }
  }
};
</script>
<style lang="less" scoped>
#components-layout-demo-basic {
  background: #fff;
  // width: 1920px;
  .ant-layout,
  .ant-layout {
    background: #fff;
  }
}
</style>